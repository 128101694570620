export const extendContainer = {
    container: () => ({
        overflow: 'visible',
        position: 'relative',
        desktopFullHD: {
            marginTop: '-10rem',
        },
        tabletLarge: {
            gridColumnGap: '1rem',
        },
        desktopLarge: {
            gridColumnGap: '1.5rem',
        },
    }),
};

const containerBase = {
    position: 'relative',
    overflow: 'visible',
    marginBottom: '4rem',
};

export const extendMilacciContainer = {
    container: () => ({
        ...containerBase,

        marginBottom: '10rem',

        desktop1200: {
            marginTop: '12.5rem',
            marginBottom: 0,
        },
    }),
};

export const extendBeerContainer = {
    container: () => ({
        ...containerBase,

        marginTop: '15rem',
        marginBottom: '12rem',

        tabletLarge: {
            marginBottom: '12rem',
        },
    }),
};

export const extendTitleContainer = {
    container: () => ({
        ...containerBase,

        tabletLarge: {
            marginTop: '-4rem',
        },

        desktop1200: {
            marginTop: '-10rem',
        },
    }),
};

export const extendFlashContainer = {
    container: () => ({
        ...containerBase,
    }),
};

export const extendAimsContainer = {
    container: () => ({
        ...containerBase,

        marginTop: '15rem',

        tabletLarge: {
            marginLeft: '3rem',
            marginRight: '-4rem',
            marginTop: '5rem',
        },
    }),
};

export const extendKosikContainer = {
    container: () => ({
        ...containerBase,

        tabletLarge: {
            top: '-2rem',
        },

        desktop1200: {
            top: '-4rem',
        },
    }),
};

export const extendShapeContainer = {
    container: () => ({
        ...containerBase,

        tabletLarge: {
            marginTop: '10rem',
        },
    }),
};

export const extendTshirtContainer = {
    container: () => ({
        ...containerBase,
        marginTop: '10rem',
        marginLeft: '3.5rem',

        tabletBounds: {
            marginTop: '20rem',
        },

        desktop1202: {
            marginTop: '18rem',
            paddingLeft: '0',
        },
    }),
};

export const extendMuzeumContainer = {
    container: () => ({
        ...containerBase,

        marginTop: '20rem',
        marginBottom: '10rem',

        tablet: {
            marginBottom: '15rem',
        },

        tabletLarge: {
            marginTop: '15rem',
        },

        desktopFullHD: {
            marginLeft: '-5%',
            paddingRight: '5%',
        },
    }),
};

export const extendPasswdContainer = {
    container: () => ({
        ...containerBase,
        marginTop: '18rem',

        tabletLarge: {
            marginLeft: '3rem',
            minWidth: '800px',
        },

        desktop1202: {
            marginTop: '18rem',
            marginLeft: '0',
        },
    }),
};

export const extendCosmoContainer = {
    container: () => ({
        ...containerBase,
        marginTop: '0rem',

        tabletBounds: {
            marginTop: 0,
        },

        tablet: {
            marginTop: '-12rem',
        },

        tabletLarge: {
            paddingLeft: '4rem',
        },

        desktopLarge: {
            marginTop: '6rem',
        },
    }),
};

export const extendSocksContainer = {
    container: () => ({
        ...containerBase,
        marginTop: '15rem',

        tabletLarge: {
            marginTop: '15rem',
            marginLeft: '5rem',
        },

        desktop1202: {
            marginTop: '5rem',
            marginLeft: '0rem',
        },
    }),
};

export const extendWWFContainer = {
    container: () => ({
        ...containerBase,

        marginTop: '15rem',
        marginBottom: '10rem',

        tablet: {
            marginBottom: '4rem',
        },

        desktop1202: {
            marginTop: 0,
            paddingLeft: '3rem',
        },

        desktopLarge: {
            marginTop: '-8rem',
        },
    }),
};

export const extendOSContainer = {
    container: () => ({
        ...containerBase,
    }),
};

export const extendExpensesContainer = {
    container: () => ({
        ...containerBase,
        marginTop: '-10rem',

        tabletLarge: {
            marginTop: '-2rem',
            marginBottom: '20rem',
        },
    }),
};

export const extendUOOUContainer = {
    container: () => ({
        ...containerBase,
        marginBottom: '12rem',

        desktop1202: {
            marginTop: '-15rem',
        },
    }),
};

export const extendText = {
    text: () => ({
        fontSize: '0.875rem',
        marginTop: '1.75rem',
    }),
};
