import { ParallaxProvider } from 'react-scroll-parallax';
import React from 'react';

import { Layout } from '../modules/ui';
import { DesignPage } from '../modules/design';
import Seo from '../components/SEO';
import * as Constants from '../constants';

const Design = () => {
    const footerRef = React.useRef();

    return (
        <>
            <Seo pageId={Constants.pages.DESIGN} />
            <Layout footerRef={footerRef}>
                <ParallaxProvider>
                    <DesignPage footerRef={footerRef} />
                </ParallaxProvider>
            </Layout>
        </>
    );
};

export default Design;
