export const imageContainer = ({ isTop, isBottom }) => {
    const base = {
        position: 'relative',
        height: 'auto',
    };

    if (isTop) {
        return {
            ...base,
            height: 396,
            width: 207,
            marginLeft: '13rem',
            marginTop: '-20rem',
        };
    }

    if (isBottom) {
        return {
            ...base,
            width: 301,
            height: 373,
        };
    }

    return base;
};

export const description = () => ({
    marginTop: '12.5rem',
    display: 'block',
});
