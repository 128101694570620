export const imageContainer = ({ isTop, isMiddle, isBottom }) => {
    const base = {
        position: 'relative',
        height: 'auto',
        width: '11.375rem',
    };

    if (isTop) {
        return {
            ...base,
            marginTop: '-27rem',
            marginLeft: '12rem',

            tabletLarge: {
                marginLeft: '13.5rem',
            },
        };
    }

    if (isMiddle) {
        return {
            ...base,
            marginTop: '-30rem',
            marginLeft: '6rem',

            tabletLarge: {
                marginLeft: '6.5rem',
            },
        };
    }

    if (isBottom) {
        return {
            ...base,
            marginTop: '5rem',
            marginLeft: '0rem',

            tabletLarge: {
                marginLeft: '0rem',
            },
        };
    }
};

export const description = {
    position: 'relative',
    marginLeft: '3rem',

    tabletLarge: {
        marginLeft: 0,
    },
};
