export const image = ({ isTop }) => ({
    position: 'relative',
    marginLeft: `${isTop ? -10 : 20}px`,
    maxWidth: '300px',

    tablet: {
        maxWidth: '29rem',
    },

    tabletLarge: {
        maxWidth: '33rem',
        marginLeft: `${isTop ? 0 : 80}px`,
    },
});
