import React from 'react';
import { PropTypes } from 'prop-types';

const Overlay = ({ styles }) => (
    <div className={styles.container}>
        <svg width="796" height="437" viewBox="0 0 796 437" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_0_1)">
                <path d="M397 437H387L397 427V437Z" fill="#FF00FF" />
                <path d="M646 437L656 427H646V437Z" fill="#FF00FF" />
                <rect x="397" y="427" width="249" height="10" fill="#FF00FF" />
            </g>
            <g clipPath="url(#clip1_0_1)">
                <path d="M10 10H0L10 0V10Z" fill="#FF00FF" />
                <path d="M165 10L175 0H165V10Z" fill="#FF00FF" />
                <rect x="10" width="155" height="10" fill="#FF00FF" />
            </g>
            <g clipPath="url(#clip2_0_1)">
                <path d="M706 283H696L706 273V283Z" fill="#FF00FF" />
                <path d="M786 283L796 273H786V283Z" fill="#FF00FF" />
                <rect x="706" y="273" width="80" height="10" fill="#FF00FF" />
            </g>
            <defs>
                <clipPath id="clip0_0_1">
                    <rect width="269" height="10" fill="white" transform="translate(387 427)" />
                </clipPath>
                <clipPath id="clip1_0_1">
                    <rect width="175" height="10" fill="white" />
                </clipPath>
                <clipPath id="clip2_0_1">
                    <rect width="100" height="10" fill="white" transform="translate(696 273)" />
                </clipPath>
            </defs>
        </svg>
    </div>
);

Overlay.propTypes = {
    styles: PropTypes.shape().isRequired,
};

export default Overlay;
