import type { TRuleWithTheme } from '@ackee/fela';

import type { AwardsSectionProps } from './AwardsSection';

type Props = Pick<AwardsSectionProps, 'isCareer'>;

export const extendMainContainer: TRuleWithTheme<Props> = ({ isCareer }) => ({
    ...(isCareer && { paddingTop: 0 }),
    paddingBottom: 0,

    tabletLarge: {
        ...(isCareer && { paddingTop: 0 }),
    },
});

export const extendContainer: TRuleWithTheme<Props> = ({ theme: { colors }, isCareer }) => ({
    paddingLeft: '2rem',
    borderLeft: `1px solid ${colors.dividerGrey}`,
    marginBottom: '5rem',
    ...(isCareer && { paddingTop: 0 }),

    tabletLarge: {
        marginBottom: 0,
        ...(isCareer && { paddingTop: 0 }),
    },
});

export const logo: TRuleWithTheme = () => ({
    maxWidth: '10.5rem',
    marginTop: '1rem',
});
