export const image = ({ isTop }) => ({
    position: 'relative',
    left: `${isTop ? 0 : 2}rem`,
    top: `${isTop ? 0 : 15}rem`,
    maxWidth: `${isTop ? 8.25 : 18.75}rem`,

    tabletLarge: {
        left: `${isTop ? 0 : 5}rem`,
        top: `${isTop ? 0 : 20}rem`,
        maxWidth: `${isTop ? 15.5 : 32.625}rem`,
    },
});
