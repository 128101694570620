export const image = ({ isTop }) => ({
    position: 'relative',
    left: `${isTop ? -10 : 20}px`,
    top: `${isTop ? -80 : 0}px`,
    maxWidth: '26rem',

    tabletLarge: {
        left: `${isTop ? 0 : 80}px`,
        top: 'auto',
    },
});
