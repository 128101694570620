export const extendMainContainer = {
    container: () => ({
        paddingTop: 0,

        tabletLarge: {
            paddingTop: 0,
            paddingBottom: 0,
        },
    }),
};

export const extendTitle = {
    title: () => ({
        fontSize: '3rem',
        maxWidth: '38.25rem',
    }),
};

export const extendText = {
    text: () => ({
        maxWidth: '38.25rem',
    }),
};

export const extendVideo = {
    container: () => ({
        position: 'relative',
        width: '100%',
        top: 'unset',
        right: 'unset',

        tabletLarge: {
            width: '100%',
        },
    }),
};
