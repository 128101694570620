export const imageContainer = ({ isTop, isBottom }) => {
    return {
        position: 'relative',
        width: '11.75rem',
        height: 'auto',
        maxHeight: '387px',
        maxWidth: '11.75rem',
        marginTop: isBottom ? '12rem' : '-32rem',
        marginLeft: isTop ? '5.325rem' : 0,
    };
};
