export const imageContainer = ({ isTop, isMiddle, isBottom }) => {
    const base = {
        position: 'relative',
        width: '33.33%',
        display: 'inline-block',
        float: 'left',
    };

    if (isTop) {
        return {
            marginRight: '-8.75%',
            ...base,
        };
    }

    if (isMiddle) {
        return {
            ...base,
            marginTop: '-9%',
        };
    }

    if (isBottom) {
        return {
            marginTop: '-18%',
            tablet: {
                marginTop: '5%',
            },
            marginLeft: '-6.5%',
            ...base,
        };
    }
};

export const description = {
    position: 'absolute',
    left: 0,
    marginTop: '20%',
    width: '200%',
};
