import bg from '../../../../../static/images/loader/background.png';

export const extendContainer = {
    container: ({ theme }) => ({
        backgroundImage: `url(${bg})`,
        backgroundColor: theme.colors.darkBlue,
        backgroundSize: 'contain',
        position: 'relative',
    }),
};

export const container = {
    padding: '2rem 2rem 2.5rem 2rem',

    tabletLarge: {
        padding: '4rem 25.125rem 4.5rem 4rem',
    },
};
