export const image = ({ isTop }) => ({
    position: 'relative',
    left: `${isTop ? -0.625 : 2}rem`,
    top: `${isTop ? 22 : 0}rem`,
    maxWidth: '31.6875rem',

    tabletBounds: {
        maxWidth: '85%',
    },

    tabletLarge: {
        left: `${isTop ? 0 : 5}rem`,
        top: `${isTop ? 30 : 0}rem`,
    },
});
