import React from 'react';
import { PropTypes } from 'prop-types';
import * as UI from '../../../ui';
import { FormattedMessage } from 'react-intl';
import HeroImage from '../HeroImage';

const { DynamicContainer, ContainerType, Text, TextTypes, Title, TitleTypes } = UI;

const TeamSection = ({ rules }) => (
    <DynamicContainer extend={rules.extendMainContainer} as={ContainerType.SECTION} isFluid type={ContainerType.GRID}>
        <DynamicContainer columns={6} startAt={2}>
            <Text type={TextTypes.SECTION}>
                <FormattedMessage id="designPage.team.sectionTitle" />
            </Text>
            <Title extend={rules.extendTitle} as={TitleTypes.H2}>
                <FormattedMessage id="designPage.team.title" />
            </Title>
            <Text extend={rules.extendText} type={TextTypes.LARGE}>
                <FormattedMessage id="designPage.team.description" />
            </Text>
        </DynamicContainer>
        <DynamicContainer
            columns={5}
            extend={{
                container: {
                    overflow: 'visible',
                },
            }}
        >
            <HeroImage
                src="design-page/team.png"
                alr="design team"
                extend={{
                    imageContainer: {
                        width: '100%',
                        position: 'relative',
                        maxWidth: 'unset',

                        tabletLarge: {
                            width: '110%',
                            left: '-20%',
                            top: '-5rem',
                        },

                        desktopLarge: {
                            width: '110%',
                            left: '-20%',
                            top: '-5rem',
                        },

                        desktopFullHD: {
                            top: '-5rem',
                        },
                    },
                }}
            />
        </DynamicContainer>
    </DynamicContainer>
);

TeamSection.propTypes = {
    rules: PropTypes.shape().isRequired,
};

export default TeamSection;
