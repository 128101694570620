export const imageContainer = () => ({
    width: '36.125rem',
    maxWidth: '70%',
    top: '5rem',
    right: '0rem',
    position: 'absolute',
    zIndex: -2,

    tabletLarge: {
        display: 'block',
        top: '6rem',
        right: '5rem',
    },

    desktopLarge: {
        width: '45.75rem',
        top: '3rem',
        right: '2rem',
    },

    desktopFullHD: {
        width: '45.75rem',
        top: '15rem',
        right: '8.75rem',
    },
});
