import React from 'react';
import { PropTypes } from 'prop-types';
import * as UI from '../../../ui';
import { Parallax, useParallaxController } from 'react-scroll-parallax';
import { FormattedMessage } from 'react-intl';
import { useWindowSize } from '../../../../utils/hooks';

import Image from './Images/Image';
import BeerImage from './Images/BeerImage';
import PhonesSmallImage from './Images/PhonesSmallImage';
import KosikImage from './Images/Kosik';
import FlashSportImage from './Images/FlashSport';
import TshirtsImage from './Images/Tshirts';
import MuzeumImage from './Images/Muzeum';
import SocksImage from './Images/Socks';
import OsImage from './Images/OS';
import ExpensesImage from './Images/Expenses';
import UoouImage from './Images/Uoou';

import { content } from './content';

const { DynamicContainer, ContainerType, Text, TextTypes } = UI;

const GallerySection = ({ rules }) => {
    const parallaxController = useParallaxController();

    const handleLoad = () => (typeof document !== 'undefined' ? parallaxController.update() : undefined);

    const windowSize = useWindowSize();
    const forcedColumnsCount = windowSize.width > 767 && windowSize.width < 1440 ? 6 : false;
    const isTabletResolution = windowSize.width <= 1023 && windowSize.width >= 768;

    return (
        <DynamicContainer extend={rules.extendContainer} as={ContainerType.SECTION} type={ContainerType.GRID} isFluid>
            <DynamicContainer columns={10} startAt={2} extend={rules.extendTitleContainer}>
                <Text type={TextTypes.SECTION}>
                    <FormattedMessage id="designPage.gallery.sectionTitle" />
                </Text>
            </DynamicContainer>

            {/* FLASHSPORT */}
            <DynamicContainer extend={rules.extendFlashContainer} columns={forcedColumnsCount || 4}>
                <Parallax translateY={[-20, 20]} tagOuter="figure">
                    <FlashSportImage
                        onLoad={handleLoad}
                        isBottom
                        src={content.smallPhones.bottom}
                        alt={content.smallPhones.bottomAlt}
                        description={
                            <Text extend={rules.extendText}>
                                <FormattedMessage id={content.smallPhones.description} />
                            </Text>
                        }
                    />
                </Parallax>
                <Parallax translateY={[-30, 30]} tagOuter="figure">
                    <FlashSportImage
                        onLoad={handleLoad}
                        isTop
                        src={content.smallPhones.top}
                        alt={content.smallPhones.topAlt}
                    />
                </Parallax>
            </DynamicContainer>

            {/* AIMS */}
            <DynamicContainer extend={rules.extendAimsContainer} columns={forcedColumnsCount || 4}>
                <Parallax translateY={[-20, 20]} tagOuter="figure">
                    <Image onLoad={handleLoad} isTop src={content.aims.top} alt={content.aims.topAlt} />
                </Parallax>
                <Parallax translateY={[-50, 10]} tagOuter="figure">
                    <Image
                        onLoad={handleLoad}
                        isBottom
                        src={content.aims.bottom}
                        alt={content.aims.bottomAlt}
                        description={
                            <Text extend={rules.extendText}>
                                <FormattedMessage id={content.aims.description} />
                            </Text>
                        }
                    />
                </Parallax>
            </DynamicContainer>

            {/* KOSIK */}
            <DynamicContainer
                extend={rules.extendKosikContainer}
                {...(!forcedColumnsCount && { startAt: 11 })}
                columns={forcedColumnsCount || 4}
            >
                <Parallax translateY={[-20, 20]} tagOuter="figure">
                    <KosikImage
                        onLoad={handleLoad}
                        isBottom
                        src={content.kosik.bottom}
                        alt={content.kosik.bottomAlt}
                        description={
                            <Text extend={rules.extendText}>
                                <FormattedMessage id={content.kosik.description} />
                            </Text>
                        }
                    />
                </Parallax>
            </DynamicContainer>

            {/* MUZEUM */}
            <DynamicContainer extend={rules.extendMuzeumContainer} columns={forcedColumnsCount || 6}>
                <Parallax translateY={['-20px', '20px']} tagOuter="figure">
                    <MuzeumImage
                        onLoad={handleLoad}
                        isTop
                        src={content.muzeum.muzeum1.src}
                        alt={content.muzeum.muzeum2.alt}
                        description={
                            <Text extend={rules.extendText}>
                                <FormattedMessage id={content.muzeum.description} />
                            </Text>
                        }
                    />
                </Parallax>
                <Parallax
                    translateY={['-50px', '50px']}
                    tagOuter="figure"
                    styleOuter={{ zIndex: 2, position: 'relative' }}
                >
                    <MuzeumImage isMiddle src={content.muzeum.muzeum2.src} alt={content.muzeum.muzeum2.alt} />
                </Parallax>
                <Parallax translateY={['-80px', '80px']} tagOuter="figure">
                    <MuzeumImage
                        onLoad={handleLoad}
                        isBottom
                        src={content.muzeum.muzeum3.src}
                        alt={content.muzeum.muzeum3.alt}
                    />
                </Parallax>
            </DynamicContainer>

            {/* MILACCI */}
            <DynamicContainer extend={rules.extendMilacciContainer} columns={forcedColumnsCount || 6}>
                <Parallax translateY={[-20, 20]} tagOuter="figure">
                    <Image onLoad={handleLoad} isTop src={content.milacci.bottom} alt={content.milacci.bottomAlt} />
                </Parallax>
                <Parallax translateY={[-50, 20]} tagOuter="figure">
                    <Image
                        onLoad={handleLoad}
                        isBottom
                        src={content.milacci.top}
                        alt={content.milacci.topAlt}
                        description={
                            <Text extend={rules.extendText}>
                                <FormattedMessage id={content.milacci.description} />
                            </Text>
                        }
                    />
                </Parallax>
            </DynamicContainer>

            {/* OS */}
            <DynamicContainer extend={rules.extendOSContainer} columns={forcedColumnsCount || 5}>
                <Parallax translateY={[-20, 20]} tagOuter="figure">
                    <OsImage onLoad={handleLoad} isTop src={content.os.bottom} alt={content.os.bottomAlt} />
                </Parallax>
                <Parallax translateY={[-50, -10]} tagOuter="figure">
                    <OsImage
                        onLoad={handleLoad}
                        isBottom
                        src={content.os.top}
                        alt={content.os.topAlt}
                        description={
                            <Text extend={rules.extendText}>
                                <FormattedMessage id={content.os.description} />
                            </Text>
                        }
                    />
                </Parallax>
            </DynamicContainer>

            {/* SHAPE OF SOUND */}
            <DynamicContainer extend={rules.extendShapeContainer} columns={forcedColumnsCount || 7}>
                <Parallax translateY={[-20, 20]} tagOuter="figure">
                    <Image
                        onLoad={handleLoad}
                        isBottom
                        src={content.shapeOfSound.bottom}
                        alt={content.shapeOfSound.bottomAlt}
                        isWide
                        description={
                            <Text extend={rules.extendText}>
                                <FormattedMessage id={content.shapeOfSound.description} />
                            </Text>
                        }
                    />
                </Parallax>
            </DynamicContainer>

            {/* COSMO */}
            <DynamicContainer extend={rules.extendCosmoContainer} columns={forcedColumnsCount || 4}>
                <Parallax translateY={[-30, 30]} tagOuter="figure">
                    <PhonesSmallImage
                        onLoad={handleLoad}
                        isBottom
                        src={content.cosmo.bottom}
                        alt={content.cosmo.bottomAlt}
                        description={
                            <Text extend={rules.extendText}>
                                <FormattedMessage id={content.cosmo.description} />
                            </Text>
                        }
                    />
                </Parallax>
                <Parallax translateY={[-20, 20]} tagOuter="figure">
                    <PhonesSmallImage onLoad={handleLoad} isTop src={content.cosmo.top} alt={content.cosmo.topAlt} />
                </Parallax>
            </DynamicContainer>

            {/* SOCKS */}
            <DynamicContainer extend={rules.extendSocksContainer} columns={forcedColumnsCount || 4}>
                <Parallax translateY={['-100px', '100px']} tagOuter="figure">
                    <SocksImage
                        onLoad={handleLoad}
                        isBottom
                        src={content.socks.sock1.src}
                        alt={content.socks.sock1.alt}
                        description={
                            <Text extend={rules.extendText}>
                                <FormattedMessage id={content.socks.description} />
                            </Text>
                        }
                    />
                </Parallax>

                <Parallax translateY={['-50px', '50px']} tagOuter="figure">
                    <SocksImage
                        onLoad={handleLoad}
                        isMiddle
                        src={content.socks.sock2.src}
                        alt={content.socks.sock2.alt}
                    />
                </Parallax>

                <Parallax translateY={['-20px', '20px']} tagOuter="figure">
                    <SocksImage onLoad={handleLoad} isTop src={content.socks.sock3.src} alt={content.socks.sock3.alt} />
                </Parallax>
            </DynamicContainer>

            {/* WWF */}
            <DynamicContainer extend={rules.extendWWFContainer} columns={forcedColumnsCount || 4}>
                <Parallax translateY={[-30, 30]} tagOuter="figure">
                    <PhonesSmallImage
                        onLoad={handleLoad}
                        isBottom
                        src={content.WWF.bottom}
                        alt={content.WWF.bottomAlt}
                        description={
                            <Text extend={rules.extendText}>
                                <FormattedMessage id={content.WWF.description} />
                            </Text>
                        }
                    />
                </Parallax>
                <Parallax translateY={[-20, 20]} tagOuter="figure">
                    <PhonesSmallImage onLoad={handleLoad} isTop src={content.WWF.top} alt={content.WWF.topAlt} />
                </Parallax>
            </DynamicContainer>

            {/* T-SHIRTS */}
            <DynamicContainer extend={rules.extendTshirtContainer} columns={6}>
                <Parallax translateY={['-20px', '20px']} tagOuter="figure">
                    <TshirtsImage
                        onLoad={handleLoad}
                        isBottom
                        src={content.tshirts.tshirt2.src}
                        alt={content.tshirts.tshirt2.alt}
                    />
                </Parallax>
                <Parallax translateY={['-120px', '120px']} tagOuter="figure">
                    <TshirtsImage
                        onLoad={handleLoad}
                        isMiddle
                        src={content.tshirts.tshirt1.src}
                        alt={content.tshirts.tshirt1.alt}
                    />
                </Parallax>
                <Parallax translateY={['-50px', '50px']} tagOuter="figure">
                    <TshirtsImage
                        onLoad={handleLoad}
                        isTop
                        src={content.tshirts.tshirt3.src}
                        alt={content.tshirts.tshirt3.alt}
                        description={
                            <Text extend={rules.extendText}>
                                <FormattedMessage id={content.tshirts.description} />
                            </Text>
                        }
                    />
                </Parallax>
            </DynamicContainer>

            {/* EXPENSES */}
            <DynamicContainer extend={rules.extendExpensesContainer} columns={6}>
                <Parallax translateY={[-20, 20]} tagOuter="figure">
                    <ExpensesImage
                        onLoad={handleLoad}
                        isTop
                        src={content.expenses.bottom}
                        alt={content.expenses.bottomAlt}
                        description={
                            !isTabletResolution && (
                                <Text extend={rules.extendText}>
                                    <FormattedMessage id={content.expenses.description} />
                                </Text>
                            )
                        }
                    />
                </Parallax>
                <Parallax translateY={[-50, 20]} tagOuter="figure">
                    <ExpensesImage
                        onLoad={handleLoad}
                        isBottom
                        src={content.expenses.top}
                        alt={content.expenses.topAlt}
                        description={
                            isTabletResolution && (
                                <Text extend={rules.extendText}>
                                    <FormattedMessage id={content.expenses.description} />
                                </Text>
                            )
                        }
                    />
                </Parallax>
            </DynamicContainer>

            {/* VÁCLAV */}
            <DynamicContainer extend={rules.extendBeerContainer} columns={6}>
                <Parallax translateY={[-20, 20]} tagOuter="figure">
                    <BeerImage onLoad={handleLoad} isBottom src={content.beer.bottom} alt={content.beer.bottomAlt} />
                </Parallax>
                <Parallax translateY={[-30, 30]} tagOuter="figure">
                    <BeerImage
                        onLoad={handleLoad}
                        isTop
                        src={content.beer.top}
                        alt={content.beer.topAlt}
                        description={
                            <Text extend={rules.extendText}>
                                <FormattedMessage id={content.beer.description} />
                            </Text>
                        }
                    />
                </Parallax>
            </DynamicContainer>

            {/* UOOU */}
            <DynamicContainer extend={rules.extendUOOUContainer} columns={6}>
                <Parallax translateY={[-20, 20]} tagOuter="figure">
                    <UoouImage
                        onLoad={handleLoad}
                        isBottom
                        src={content.uoou.bottom}
                        alt={content.uoou.bottomAlt}
                        description={
                            <Text extend={rules.extendText}>
                                <FormattedMessage id={content.uoou.description} />
                            </Text>
                        }
                    />
                </Parallax>
                <Parallax translateY={[-50, 20]} tagOuter="figure">
                    <UoouImage onLoad={handleLoad} isTop src={content.uoou.top} alt={content.uoou.topAlt} />
                </Parallax>
            </DynamicContainer>
        </DynamicContainer>
    );
};

GallerySection.propTypes = {
    styles: PropTypes.shape().isRequired,
    rules: PropTypes.shape().isRequired,
    parallaxController: PropTypes.shape().isRequired,
};

export default GallerySection;
