export const extendMainContainer = {
    container: () => ({
        paddingTop: 0,
        paddingBottom: 0,

        tabletLarge: {
            paddingTop: 0,
            paddingBottom: 0,
        },
    }),
};

export const divider = ({ theme: { colors } }) => ({
    height: '10rem',
    width: '0.0625rem',
    background: colors.dividerGrey,
    marginLeft: '3rem',
    marginBottom: '6.25rem',

    tabletLarge: {
        height: '15rem',
        marginLeft: '12rem',
    },
});

export const extendTitle = {
    title: () => ({
        fontSize: '3rem',
        maxWidth: '38.25rem',
    }),
};

export const extendText = {
    text: () => ({
        maxWidth: '38.25rem',
    }),
};

export const extendVideo = {
    container: () => ({
        position: 'relative',
        width: '100%',
        top: 'unset',
        right: 'unset',

        tabletLarge: {
            width: '100%',
        },
    }),
};
