export const imageContainer = () => ({
    position: 'relative',
    width: '16.625rem',
    height: 'auto',
    maxHeight: '387px',
    maxWidth: '100%',
    marginTop: 0,

    left: '2rem',

    desktopLarge: {
        width: '16.625rem',
        top: '2.5rem',
        left: '-5rem',
    },
});
