export const content = {
    milacci: {
        bottom: 'design-page/gallery/milacci-bottom.png',
        bottomAlt: 'designPage.gallery.milacci.bottomAlt',
        top: 'design-page/gallery/milacci-top.png',
        topAlt: 'designPage.gallery.milacci.topAlt',
        description: 'designPage.gallery.milacci.description',
    },
    smallPhones: {
        bottom: 'design-page/gallery/flashsport-small-bottom.png',
        bottomAlt: 'designPage.gallery.smallPhones.bottomAlt',
        top: 'design-page/gallery/flashsport-small-top.png',
        topAlt: 'designPage.gallery.smallPhones.topAlt',
        description: 'designPage.gallery.smallPhones.description',
    },
    beer: {
        bottom: 'design-page/gallery/beer-bottom.jpg',
        bottomAlt: 'designPage.gallery.beer.bottomAlt',
        top: 'design-page/gallery/beer-top.png',
        topAlt: 'designPage.gallery.beer.topAlt',
        description: 'designPage.gallery.beer.description',
    },
    kosik: {
        bottom: 'design-page/gallery/kosik.jpg',
        bottomAlt: 'designPage.gallery.kosik.bottomAlt',
        description: 'designPage.gallery.kosik.description',
    },
    shapeOfSound: {
        bottom: 'design-page/gallery/shape-of-sound.png',
        bottomAlt: 'designPage.gallery.shapeOfSound.bottomAlt',
        description: 'designPage.gallery.shapeOfSound.description',
    },
    tshirts: {
        tshirt1: {
            src: 'design-page/gallery/tshirt-1.png',
            alt: 'designPage.gallery.tshirts.1.alt',
        },
        tshirt2: {
            src: 'design-page/gallery/tshirt-2.png',
            alt: 'designPage.gallery.tshirts.2.alt',
        },
        tshirt3: {
            src: 'design-page/gallery/tshirt-3.png',
            alt: 'designPage.gallery.tshirts.3.alt',
        },
        description: 'designPage.gallery.tshirts.description',
    },
    muzeum: {
        muzeum1: {
            src: 'design-page/gallery/muzeum-1.png',
            alt: 'designPage.gallery.muzeum.1.alt',
        },
        muzeum2: {
            src: 'design-page/gallery/muzeum-2.png',
            alt: 'designPage.gallery.muzeum.2.alt',
        },
        muzeum3: {
            src: 'design-page/gallery/muzeum-3.png',
            alt: 'designPage.gallery.muzeum.3.alt',
        },
        description: 'designPage.gallery.muzeum.description',
    },
    passwd: {
        bottom: 'design-page/gallery/passwd.jpg',
        bottomAlt: 'designPage.gallery.passwd.bottomAlt',
        description: 'designPage.gallery.passwd.description',
    },
    cosmo: {
        bottom: 'design-page/gallery/cosmo-bottom.png',
        bottomAlt: 'designPage.gallery.cosmo.bottomAlt',
        top: 'design-page/gallery/cosmo-top.png',
        topAlt: 'designPage.gallery.cosmo.topAlt',
        description: 'designPage.gallery.cosmo.description',
    },
    socks: {
        sock1: {
            src: 'design-page/gallery/sock-1.png',
            alt: 'designPage.gallery.socks.1.alt',
        },
        sock2: {
            src: 'design-page/gallery/sock-2.png',
            alt: 'designPage.gallery.socks.2.alt',
        },
        sock3: {
            src: 'design-page/gallery/sock-3.png',
            alt: 'designPage.gallery.socks.3.alt',
        },
        description: 'designPage.gallery.socks.description',
    },
    nova: {
        bottom: 'design-page/gallery/nova.jpg',
        bottomAlt: 'designPage.gallery.nova.bottomAlt',
        description: 'designPage.gallery.nova.description',
    },
    WWF: {
        bottom: 'design-page/gallery/wwf-bottom.png',
        bottomAlt: 'designPage.gallery.WWF.bottomAlt',
        top: 'design-page/gallery/wwf-top.png',
        topAlt: 'designPage.gallery.WWF.topAlt',
        description: 'designPage.gallery.WWF.description',
    },
    os: {
        bottom: 'design-page/gallery/os-bottom.png',
        bottomAlt: 'designPage.gallery.os.bottomAlt',
        top: 'design-page/gallery/os-top.png',
        topAlt: 'designPage.gallery.os.topAlt',
        description: 'designPage.gallery.os.description',
    },
    expenses: {
        bottom: 'design-page/gallery/expenses-bottom.png',
        bottomAlt: 'designPage.gallery.expenses.bottomAlt',
        top: 'design-page/gallery/expenses-top.png',
        topAlt: 'designPage.gallery.expenses.topAlt',
        description: 'designPage.gallery.expenses.description',
    },
    uoou: {
        bottom: 'design-page/gallery/uoou-bottom.jpg',
        bottomAlt: 'designPage.gallery.uoou.bottomAlt',
        top: 'design-page/gallery/uoou-top.jpg',
        topAlt: 'designPage.gallery.uoou.topAlt',
        description: 'designPage.gallery.uoou.description',
    },
    aims: {
        bottom: 'design-page/gallery/aims-bottom.png',
        bottomAlt: 'designPage.gallery.aims.bottomAlt',
        top: 'design-page/gallery/aims-top.png',
        topAlt: 'designPage.gallery.aims.topAlt',
        description: 'designPage.gallery.aims.description',
    },
};
