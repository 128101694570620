import React from 'react';
import { PropTypes } from 'prop-types';
import * as UI from '../../../ui';

const { DynamicContainer, ContainerType, Quote } = UI;

const QuoteSection = ({ styles, rules, theme }) => {
    const { colors } = theme;

    return (
        <DynamicContainer extend={rules.extendContainer} as={ContainerType.SECTION} dark>
            <DynamicContainer isFluid>
                <div className={styles.container}>
                    <Quote message="designPage.quoteSection.quote" color={colors.white} />
                </div>
            </DynamicContainer>
        </DynamicContainer>
    );
};

QuoteSection.propTypes = {
    styles: PropTypes.shape({
        container: PropTypes.string.isRequired,
    }).isRequired,
    rules: PropTypes.shape().isRequired,
    theme: PropTypes.shape().isRequired,
};

export default QuoteSection;
