export const extendContainer = {
    container: ({ theme: { colors } }) => ({
        borderTop: `1px solid ${colors.lightGrey}`,
        borderBottom: `1px solid ${colors.lightGrey}`,
    }),
};

export const divider = ({ theme: { colors } }) => ({
    display: 'none',

    tabletLarge: {
        borderTop: `0.0625rem solid ${colors.lightGrey}`,
        marginBottom: '10rem',
    },
});
