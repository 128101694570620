//  this cannot be imported from dependencies
import { graphql, useStaticQuery } from 'gatsby';

import React from 'react';
import { PropTypes } from 'prop-types';
import { useIntl } from 'react-intl';
import Img from 'gatsby-image';

const PhonesSmallImage = ({ styles, src, alt, description, onLoad }) => {
    const { formatMessage } = useIntl();
    const imageAlt = formatMessage({ id: alt });

    const images = useStaticQuery(graphql`
        query {
            allFile(filter: { relativeDirectory: { eq: "design-page/gallery" } }) {
                nodes {
                    base
                    relativePath
                    childImageSharp {
                        fluid(pngQuality: 90, maxHeight: 387) {
                            ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        }
                    }
                }
            }
        }
    `);

    const match = React.useMemo(() => images.allFile.nodes.find(({ relativePath }) => src === relativePath), [
        images,
        src,
    ]);

    const {
        childImageSharp: { fluid },
    } = match;

    return (
        <div className={styles.imageContainer}>
            <Img onLoad={onLoad} alt={imageAlt} className={styles.image} fluid={fluid} />
            {description && <span className={styles.description}>{description}</span>}
        </div>
    );
};

PhonesSmallImage.propTypes = {
    styles: PropTypes.shape().isRequired,
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
};

export default PhonesSmallImage;
