import React from 'react';
import { PropTypes } from 'prop-types';
import * as UI from '../../../ui';
import { FormattedMessage } from 'react-intl';
import HeroImage from '../HeroImage';

const { DynamicContainer, ContainerType, Text, TextTypes, Title, TitleTypes } = UI;

const DesignSection = ({ styles, rules }) => (
    <DynamicContainer extend={rules.extendMainContainer} as={ContainerType.SECTION} isFluid type={ContainerType.GRID}>
        <DynamicContainer columns={12}>
            <div className={styles.divider} />
        </DynamicContainer>
        <DynamicContainer columns={6} startAt={2}>
            <Text type={TextTypes.SECTION}>
                <FormattedMessage id="designPage.design.sectionTitle" />
            </Text>
            <Title extend={rules.extendTitle} as={TitleTypes.H2}>
                <FormattedMessage id="designPage.design.title" />
            </Title>
            <Text extend={rules.extendText} type={TextTypes.LARGE}>
                <FormattedMessage id="designPage.design.description" />
            </Text>
        </DynamicContainer>
        <DynamicContainer
            extend={{
                container: {
                    overflow: 'visible',
                },
            }}
            columns={5}
        >
            <HeroImage
                src="design-page/design.png"
                alt="custom app design"
                extend={{
                    imageContainer: {
                        width: '100%',
                        position: 'relative',
                        maxWidth: 'unset',

                        tabletLarge: {
                            width: '110%',
                            left: '-10%',
                            top: '-5rem',
                        },

                        desktopLarge: {
                            width: '110%',
                            left: '-10%',
                            top: '-5rem',
                        },

                        desktopFullHD: {
                            width: '110%',
                            left: '-10%',
                            top: '-5rem',
                        },
                    },
                }}
            />
        </DynamicContainer>

        <DynamicContainer columns={12}>
            <div className={styles.dividerMiddle} />
        </DynamicContainer>
    </DynamicContainer>
);

DesignSection.propTypes = {
    styles: PropTypes.shape().isRequired,
    rules: PropTypes.shape().isRequired,
};

export default DesignSection;
