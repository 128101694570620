export const imageContainer = ({ isTop, isMiddle, isBottom }) => {
    const base = {
        position: 'relative',
        height: 'auto',
        width: '18.6875rem',
    };

    if (isTop) {
        return {
            ...base,
            marginTop: '-3rem',
            marginLeft: '1.125rem',

            tabletLarge: {
                marginLeft: '3.125rem',
            },
        };
    }

    if (isMiddle) {
        return {
            ...base,
            marginTop: '-25rem',
            marginLeft: '-3.5rem',

            tabletLarge: {
                marginLeft: '0rem',
            },
        };
    }

    if (isBottom) {
        return {
            ...base,
            marginTop: '10rem',
            marginLeft: '5.5rem',

            tabletLarge: {
                marginLeft: '10.5rem',
            },
        };
    }
};
