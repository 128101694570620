import * as images from '../../../../images/design-page/awards';

const ids = ['GBA', 'AMA'] as const;

export const content = ids.map(
    id =>
        ({
            year: `designPage.awards.${id}.year`,
            title: `designPage.awards.${id}.title`,
            description: `designPage.awards.${id}.description`,
            // eslint-disable-next-line import/namespace
            image: images[id],
            alt: `designPage.awards.${id}.imageAlt`,
            id,
        } as const),
);
