import React from 'react';
import { PropTypes } from 'prop-types';
import * as UI from '../../../ui';
import { FormattedMessage } from 'react-intl';
import HeroImage from '../HeroImage';

const { DynamicContainer, ContainerType, Text, TextTypes, Title, TitleTypes, FormattedMessageWithLinks } = UI;

const SubHeroSection = ({ styles, rules, reference }) => (
    <DynamicContainer
        extend={rules.extendMainContainer}
        ref={reference}
        as={ContainerType.SECTION}
        isFluid
        type={ContainerType.GRID}
    >
        <DynamicContainer columns={12}>
            <div className={styles.divider} />
        </DynamicContainer>

        {/* Section 1 */}
        <DynamicContainer columns={6} startAt={2}>
            <Text type={TextTypes.SECTION}>
                <FormattedMessage id="designPage.subHero.sectionTitle1" />
            </Text>
            <Title extend={rules.extendTitle} as={TitleTypes.H2}>
                <FormattedMessage id="designPage.subHero.title1" />
            </Title>
            <Text extend={rules.extendText} type={TextTypes.LARGE}>
                <FormattedMessageWithLinks id="designPage.subHero.description1" />
            </Text>
        </DynamicContainer>
        <DynamicContainer
            extend={{
                container: rules.sectionImage,
            }}
            columns={5}
        >
            <HeroImage
                src="design-page/research.png"
                alt="design research"
                extend={{
                    imageContainer: {
                        width: '100%',
                        position: 'relative',
                        maxWidth: 'unset',

                        tabletLarge: {
                            width: '110%',
                            left: '-20%',
                            top: '-5rem',
                        },

                        desktopLarge: {
                            width: '110%',
                            left: '-20%',
                            top: '-5rem',
                        },

                        desktopFullHD: {
                            top: '-5rem',
                        },
                    },
                }}
            />
            {/* <Image src="design-page/sub-hero/1.jpg" alt="designPage.subHero.imageAlt1" /> */}
        </DynamicContainer>

        <DynamicContainer columns={12}>
            <div className={styles.dividerMiddle} />
        </DynamicContainer>

        {/* Section 2 */}
        <DynamicContainer columns={6} startAt={2}>
            <Text type={TextTypes.SECTION}>
                <FormattedMessage id="designPage.subHero.sectionTitle2" />
            </Text>
            <Title extend={rules.extendTitle} as={TitleTypes.H2}>
                <FormattedMessage id="designPage.subHero.title2" />
            </Title>
            <Text extend={rules.extendText} type={TextTypes.LARGE}>
                <FormattedMessageWithLinks id="designPage.subHero.description2" />
            </Text>
        </DynamicContainer>
        <DynamicContainer
            extend={{
                container: rules.sectionImage,
            }}
            columns={5}
        >
            <HeroImage
                src="design-page/prototype.png"
                alt="Design prototype"
                extend={{
                    imageContainer: {
                        width: '100%',
                        position: 'relative',
                        maxWidth: 'unset',

                        tabletLarge: {
                            width: '120%',
                            left: '-30%',
                            top: '-5rem',
                        },

                        desktopLarge: {
                            width: '120%',
                            left: '-30%',
                            top: '-5rem',
                        },

                        desktopFullHD: {
                            top: '-5rem',
                        },
                    },
                }}
            />
        </DynamicContainer>

        <DynamicContainer columns={12}>
            <div className={styles.dividerLast} />
        </DynamicContainer>
    </DynamicContainer>
);

SubHeroSection.propTypes = {
    styles: PropTypes.shape().isRequired,
    rules: PropTypes.shape().isRequired,
};

export default SubHeroSection;
