import React from 'react';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { InquirySection } from '../../../inquiry';
import HeroSection from '../../../hero-section';
import * as UI from '../../../ui';

import BlogSection from '../BlogSection';
import QuoteSection from '../QuoteSection';
import SubHeroSection from '../SubHeroSection';
import DesignSection from '../DesignSection';
import TeamSection from '../TeamSection';
import GallerySection from '../GallerySection';
import { AwardsSection } from '../AwardsSection';
import HeroImage from '../HeroImage';
import { Offices } from '../../../offices';

const { SectionNavigation, sectionTitles, TitleTypes } = UI;

const DesignPage = ({ footerRef }) => {
    const heroRef = React.useRef();
    const processRef = React.useRef();
    const officesRef = React.useRef();

    const sections = {
        hero: {
            ref: heroRef,
            title: sectionTitles.hero.general,
            id: 1,
        },
        process: {
            ref: processRef,
            title: sectionTitles.process.design,
            id: 2,
        },
        offices: {
            ref: officesRef,
            title: sectionTitles.offices.design,
            id: 3,
        },
        footer: {
            ref: footerRef,
            title: sectionTitles.footer,
            id: 4,
        },
    };

    return (
        <>
            <HeroSection
                ref={heroRef}
                sectionTitle={<FormattedMessage id="designPage.hero.sectionTitle" />}
                title={<FormattedMessage id="designPage.hero.title" />}
                titleAs={TitleTypes.H1}
                description={<FormattedMessage id="designPage.hero.description" />}
                customContent={<HeroImage src="design-page/design_header.png" />}
            />
            <SubHeroSection reference={processRef} />
            <QuoteSection />
            <DesignSection />
            <GallerySection />
            <TeamSection />
            <AwardsSection />
            <BlogSection />
            <Offices />
            <InquirySection ref={officesRef} />
            <SectionNavigation sections={sections} />
        </>
    );
};

DesignPage.propTypes = {
    footerRef: PropTypes.shape().isRequired,
};

export default DesignPage;
