export const imageContainer = ({ isTop, isBottom }) => ({
    position: 'relative',
    width: isBottom ? '13.625rem' : '7.5625rem',
    height: 'auto',
    maxHeight: '387px',
    marginTop: isBottom ? '5rem' : '-20.825rem',
    marginLeft: isTop ? '10rem' : '0rem',

    tabletLarge: {
        marginLeft: isTop ? '16.125rem' : '4.8rem',
    },
});

export const description = () => ({
    display: 'block',
    marginTop: '1.5rem',
    position: 'relative',
    marginLeft: '-8.9375rem',

    tabletLarge: {
        marginLeft: '-8.9375rem',
    },
});
