import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useFelaEnhanced } from '@ackee/fela';

import { DynamicContainer, ContainerType, Title, TitleTypes, Text, TextTypes } from 'modules/ui';

import { content } from './content';

import * as felaRules from './AwardsSection.rules';

export interface AwardsSectionProps {
    isCareer?: boolean;
}

export const AwardsSection = ({ isCareer }: AwardsSectionProps) => {
    const { styles, rules } = useFelaEnhanced(felaRules);
    return (
        <DynamicContainer
            extend={{ container: rules.extendMainContainer }}
            isCareer={isCareer}
            isFluid
            type={ContainerType.GRID}
            as={ContainerType.SECTION}
        >
            {/* @ts-expect-error */}
            <Text columns={12} as="h2" type={TextTypes.SECTION_BLACK}>
                <FormattedMessage id="designPage.awards.title" />
            </Text>
            {content.map(item => (
                <DynamicContainer key={item.id} extend={{ container: rules.extendContainer }} columns={6}>
                    {/* @ts-expect-error */}
                    <Text type={TextTypes.SECTION}>
                        <FormattedMessage id={item.year} />
                    </Text>
                    {/* @ts-expect-error */}
                    <Title as={TitleTypes.H3} type={TitleTypes.H4}>
                        <FormattedMessage id={item.title} />
                    </Title>
                    {/* @ts-expect-error */}
                    <Text as="p" type={TextTypes.LARGE}>
                        <FormattedMessage id={item.description} />
                    </Text>
                    <img className={styles.logo} alt={item.alt} src={item.image} />
                </DynamicContainer>
            ))}
        </DynamicContainer>
    );
};
